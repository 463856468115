<template>
    <div>
    <default-app-bar> </default-app-bar>
    <div class="d-flex  flex-column justify-center align-center" style="max-width: 800px;margin-left:auto;margin-right: auto">
     <v-row style="width: 100%">
           <v-col cols="12" md="6">
             <div class="text-h2"> <v-icon large>mdi-apps-box</v-icon> {{ $t('Permisos Sistema') }}</div>
           </v-col>
      </v-row>
      <v-card width="100%">
        <v-card-text>
          <v-container>
           <v-row no-gutters> 
            <v-col cols="12" sm="12" class="form-col"  > 
              <label>Seleccione el perfil que desea modificar</label>
                <ng-select  v-bind:form="form"  @change="change" v-bind:options="config.sys_group_id"></ng-select>
            </v-col>
        
          </v-row>

        </v-container>
        </v-card-text>
        <v-divider></v-divider>
              <div :key="renderKey" style="width: 100%">
      <div v-if="items"   style="padding: 10px">
        <label>Seleccione los permisos que desea asignar al perfil</label>
        <!--<div class="text-right"><v-btn small color="primary" v-on:click="save()">Guardar</v-btn></div-->
        <!-- -->
        <v-treeview dense :items="items" selection-type="independent" selectable v-model="selection"  v-on:input="changeSelection" ></v-treeview>
        <div class="text-right"><v-btn small color="primary" v-on:click="save()">Guardar</v-btn></div>
      </div>
    </div>
      </v-card>

  </div>
  </div>
</template>

<script>
  import { NgForm, handler } from '@/components/gui/NgForm';
  import {
    mapState,
  } from 'vuex'
  
  export default {
    data(){
        return {
          form: { sys_group_id: null },

          config: {
            // change: 'hook_change_group_config'
            sys_group_id: { label: 'Perfil', name: 'sys_group_id', url: 'api/v1/admin/group/search', size: 12 },
          },
          renderKey: 1,
          items: null,
          tmpl: '',
          selection: []
        }
    },
    created() {
     
      const form = new NgForm(this.config, this)
      this.form = new Proxy(form, handler)
  
      this.form.init();
    },
    methods: {
      change(evt){
        this.$http.post('api/v1/auth/perms-group',  { data: { sys_group_id: evt }}, this, true, false).then((data) => {
        this.setMenuItems(data.perms, data.permsByGroup);

       });
      },
      changeSelection(event){
        console.log(event)
      },
      save(){
         this.$http.post('api/v1/auth/perms-group/save', { data: { perms: this.selection, sys_group_id: this.form.sys_group_id } }, this, true, true).then((data) => {
            console.log(data)
         });
      },
      setMenuItems(items, selection){
          this.selection = selection;
          this.items = items;
          this.renderKey++;
          //this.$forceUpdate();
      }
    }
  }
</script>

